
.Footer {
  background-color: black;
  border-block: 1px solid white;
  .Footer-link-wrap {
    display: flex;
    justify-content: center;
    flex-direction: row;
    .Footer-links {
      margin-inline: 20px;
      margin-block: 10px;
    }
  }
}