@import './PageNavbar.scss';
@import './Footer.scss';
@import './About.scss';
@import './Skills.scss';
@import './Experience.scss';
@import './Projects.scss';
@import './Header.scss';
@import './Hobbies.scss';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 36px;
  color: rgb(147, 250, 165);
}


h2 {
  color: rgb(147, 250, 165);
}

h3 {
  color: grey;
}

p {
  color: black;
}

.Source-code, .Website {
  border-radius: 6px;
  height: 45px;
  width: 120px;
  background: white; 
  background: rgba(255, 255, 255, 0.5); 
  border-radius: 8px; 
  color: black;
  font-weight: bold;
  margin-inline: 5px;
  text-align: center; 
  text-decoration: none; 
  letter-spacing: 1px; 
  transition: all 0.3s ease-out;
  border-color: rgb(147, 250, 165);
  &:hover {
    background: rgb(32, 36, 33); 
    // background: rgba(73, 72, 72, 0.5);
    color: rgb(147, 250, 165);
  }
}


html{scroll-behavior:smooth}