

.About-page {
  background-color: black;
  padding-block: 90px 75px;
  border-block: 1px solid white;
  #About-statement {
    color: white;
  }
  .About-container {
    display: flex;
    justify-content:center;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    font-size: 22px;
    padding-bottom: 40px;
    padding-inline: 10px;
    .About {
      padding-bottom: 30px;
    }
  }
}