.Skills-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: black;
  padding-block: 100px;
  max-width: 100vw;
  border-block: 1px solid white;
  #Skills-title {
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

  }
  .Top-skills-container {
    display: flex;
    margin-block: 15px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .One-skill-container {
    align-items: center;
    text-align: center;
    margin-inline: 30px;
    padding-bottom: 20px;
  }
  h2 {
    padding-bottom: 5px;
  }
  .Skill-image {
    // padding-bottom: 20px;
  }
}