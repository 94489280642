
.Experience-container {
  background-color: black;
  display: flex;
  justify-content:center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-block:  80px;
  padding-inline: 20px;
  border-block: 1px solid white;
  #Experience-title {
    margin: 0 auto;
    padding-block: 30px;
  }
  .General-assembly {
    margin: 0 auto;
    font-size: 26px;
  }
  .Experience-description {
    font-size: 22px;
    padding-top: 30px;
    color: white;
  }
}